import React from "react";
import logo from "./logo.svg";
import "./App.css";
import BackgroundImg from "./assets/background.jpg";
import LogoImg from "./assets/logo.webp";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";
import Button from "@mui/material/Button";
import MintButton from "./mint/mint";

function App() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${BackgroundImg})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          // backgroundColor: "white",
          // padding: "4em",
          borderRadius: 30,
          background: "rgba(255,255,255,0.6)",
          backdropFilter: "blur(10px)",
          width: 500,
          paddingTop: "2em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={LogoImg} />
          <div
            style={{
              fontSize: 20,
              marginTop: "1em",
            }}
          >
            Koobi Carbon Offset NFT for 2022
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 300,
              width: 400,
              textAlign: "justify",
              marginTop: "1em",
            }}
          >
            Ownership of a <i>Koobi 2022 - Lion Carbon</i> NFT verifies that you have offset
            your carbon footprint in 2022. Each NFT represents 22 tons of carbon
            sequestered - the yearly emission of the average western lifestyle.
          </div>
          <MintButton />
          {/* <Button
            variant="contained"
            sx={{
              marginTop: "2.5em",
              width: 250,
              marginBottom: "2.5em",
              backgroundColor: "#CA633A",
              height: 45,
              borderRadius: 20,
              "&:hover": {
                backgroundColor: "#e87243",
              },
              textTransform: "none",
              fontStyle: "Roboto",
              fontWeight: 300,
              fontSize: 16,
            }}
          >
            Connect Wallet
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default App;
